import { combineReducers } from "@reduxjs/toolkit";

import usersReducer from "./users";
import doctorReducer from "./doctors";
import customizationReducer from "./customizationReducer";
import appointmentsReducer from "./appointments";

export default combineReducers({
  customization: customizationReducer,
  users: usersReducer,
  doctors: doctorReducer,
  appointments: appointmentsReducer,
});
