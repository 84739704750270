import { ApiErrorMessages } from "constants";
import { toast } from "react-toastify";
import ApiClient from "./ApiClient";

const featureSpecialities = () =>
  ApiClient.get("/speciality/featured-specialities").catch(() =>
    toast.error(ApiErrorMessages.featureSpecialities)
  );

const getRelevantSpecialities = (query) =>
  ApiClient.get(`/users/doctors-with-specialities-mentioned?${query}`).catch(
    () => toast.error(ApiErrorMessages.getRelevantSpecialities)
  );

const getDoctorTimeSlots = async (userId, fromDate, isAllRequired) => {
  return await ApiClient.post(
    `/users/doctor-with-timeslots/${userId}/${isAllRequired ? `?all=${isAllRequired}` : ""
    }`, {
    fromDate: fromDate
  }
  ).catch(() => toast.error(ApiErrorMessages.getDoctorTimeSlots));
};

const getDoctorsTimeSlots = (doctorIds, fromDate) =>
  ApiClient.post(`/doctor/get-paginated-time-slots`, {
    fromDate: fromDate,
    doctorIds: doctorIds,
  }).catch(() => toast.error(ApiErrorMessages.getDoctorsTimeSlots));

const getSpecialtiesAndServices = () => 
  ApiClient.get("speciality").catch(() =>
    toast.error(ApiErrorMessages.getSpecialtiesAndServices)
  );

const getSpecialtiesByName = (name) =>
  ApiClient.get(`/speciality/get-specialty?name=${name}`).catch(() =>
    toast.error(ApiErrorMessages.getSpecialtiesAndServices)
  );

const getDoctorReviews = (doctorId) =>
  ApiClient.get(`/doctor/get-reviews?doctorId=${doctorId}`).catch(() =>
    toast.error(ApiErrorMessages.getDoctorReviews)
  );

const getServiceByName = (name) =>
  ApiClient.get(`/service/get-service?name=${name}`).catch(() =>
    toast.error(ApiErrorMessages.getSpecialtiesAndServices)
  );

const services = {
  featureSpecialities,
  getRelevantSpecialities,
  getDoctorTimeSlots,
  getDoctorsTimeSlots,
  getSpecialtiesAndServices,
  getSpecialtiesByName,
  getDoctorReviews,
  getServiceByName,
};

export default services;
