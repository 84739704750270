import axios from "axios";
import { toast } from "react-toastify";

const handleStatusCode = (status, error) => {
  switch (status) {
    case 401:
      window.location.href = '/sign-in';
      localStorage.clear();
      toast.error('Session expired. Please login again.');
      break;

    case 403:
      toast.error('You are not authorized to access this resource.');
      break;

    case 404:
      toast.error('The requested resource was not found.');
      break;

    case 500:
      toast.error('Something went wrong. Please try again later.');
      break;

    default:
      toast.error(error?.response?.data?.message || 'An unexpected error occurred.');
      break;
  }
};


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    config.headers["Content-Type"] = "application/json";
    config.headers["ngrok-skip-browser-warning"] = "true";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {

      handleStatusCode(error.response.status, error);

    } else if (error.request) {
      console.error("Request Error:", error.request);
    } else {
      console.error("Error Message:", error.message);
    }
    console.error("Error Config:", error.config);
    return Promise.reject(error);
  }
);

export default axiosInstance;

export const GET = (query) => axiosInstance.get(query);
