const topSearchServices = [
  {
    key: "",
    label: "Hair Loss",
    slug: "hair-loss",
  },
  //   {
  //     key: "",
  //     label: "Hyperactive Disorder (ADD / ADHD)",
  //     slug: "hyperactive-disorder-add-adhd",
  //   },
  //   {
  //     key: "",
  //     label: "OB / GYN Emergency",
  //     slug: "ob-gyn-emergency",
  //   },
  {
    key: "",
    label: "Nexplanon Removal",
    slug: "nexplanon-removal",
  },
  //   {
  //     key: "",
  //     label: "Pre-Surgery Checkup / Pre-Surgical Clearance",
  //     slug: "pre-surgery-checkup-pre-surgical-clearance",
  //   },
  {
    key: "",
    label: "Annual Physical",
    slug: "annual-physical",
  },
  {
    key: "",
    label: "LGBT Care",
    slug: "lgbt-care",
  },
  {
    key: "",
    label: "ENT Emergency Visit",
    slug: "ent-emergency-visit",
  },
  {
    key: "",
    label: "Pain Medication Prescription",
    slug: "pain-medication-prescription",
  },
  {
    key: "",
    label: "IUD Insertion",
    slug: "iud-insertion",
  },
  {
    key: "",
    label: "Cortisone Shots",
    slug: "cortisone-shots",
  },
  {
    key: "",
    label: "Couples Therapy",
    slug: "couples-therapy",
  },
  {
    key: "",
    label: "Gastroenterology Emergency",
    slug: "gastroenterology-emergency",
  },
  //   {
  //     key: "",
  //     label: "Marriage Counseling",
  //     slug: "marriage-counseling",
  //   },
  {
    key: "",
    label: "Dental Emergency",
    slug: "dental-emergency",
  },
  //   {
  //     key: "",
  //     label: "Prenatal Care",
  //     slug: "prenatal-care",
  //   },
  //   {
  //     key: "",
  //     label: "Cholecystectomy",
  //     slug: "cholecystectomy",
  //   },
  {
    key: "",
    label: "Dental Implants",
    slug: "dental-implants",
  },
  //   {
  //     key: "",
  //     label: "PCR Test",
  //     slug: "pcr-test",
  //   },
  //   {
  //     key: "",
  //     label: "Colposcopy",
  //     slug: "colposcopy",
  //   },
  //   {
  //     key: "",
  //     label: "Cognitive Behavioral Therapy",
  //     slug: "cognitive-behavioral-therapy",
  //   },
  //   {
  //     key: "",
  //     label: "Pap Smear",
  //     slug: "pap-smear",
  //   },
  //   {
  //     key: "",
  //     label: "CBC Blood Test",
  //     slug: "cbc-blood-test",
  //   },
  {
    key: "",
    label: "Biopsy",
    slug: "biopsy",
  },
  //   {
  //     key: "",
  //     label: "Health Screening",
  //     slug: "health-screening",
  //   },
  {
    key: "",
    label: "Elevated PSA",
    slug: "elevated-psa",
  },
  //   {
  //     key: "",
  //     label: "LDL Cholesterol",
  //     slug: "ldl-cholesterol",
  //   },
  //   {
  //     key: "",
  //     label: "Bilirubin",
  //     slug: "bilirubin",
  //   },
  //   {
  //     key: "",
  //     label: "Creatinine",
  //     slug: "creatinine",
  //   },
  //   {
  //     key: "",
  //     label: "Ferritin",
  //     slug: "ferritin",
  //   },
  //   {
  //     key: "",
  //     label: "Hematocrit",
  //     slug: "hematocrit",
  //   },
  //   {
  //     key: "",
  //     label: "Electromyography (EMG) Test",
  //     slug: "electromyography-emg-test",
  //   },
  {
    key: "",
    label: "Cystoscopy",
    slug: "cystoscopy",
  },
  //   {
  //     key: "",
  //     label: "Intense Pulsed Light (IPL) Treatment",
  //     slug: "intense-pulsed-light-ipl-treatment",
  //   },
  {
    key: "",
    label: "Laser Hair Removal",
    slug: "laser-hair-removal",
  },
  //   {
  //     key: "",
  //     label: "Vasectomy",
  //     slug: "vasectomy",
  //   },
  {
    key: "",
    label: "Hysterectomy",
    slug: "hysterectomy",
  },
  //   {
  //     key: "",
  //     label: "Bariatric Surgery",
  //     slug: "bariatric-surgery",
  //   },
  //   {
  //     key: "",
  //     label: "Lasik Eye Surgery",
  //     slug: "lasik-eye-surgery",
  //   },
  {
    key: "",
    label: "Circumcision",
    slug: "circumcision",
  },
  //   {
  //     key: "",
  //     label: "Wisdom Teeth Removal",
  //     slug: "wisdom-teeth-removal",
  //   },
  //   {
  //     key: "",
  //     label: "Cortisone Shot",
  //     slug: "cortisone-shot",
  //   },
  //   {
  //     key: "",
  //     label: "Blepharoplasty",
  //     slug: "blepharoplasty",
  //   },
  //   {
  //     key: "",
  //     label: "Tonsillectomy",
  //     slug: "tonsillectomy",
  //   },
  {
    key: "",
    label: "Laminectomy",
    slug: "laminectomy",
  },
  //   {
  //     key: "",
  //     label: "Transcranial Magnetic Stimulation (TMS)",
  //     slug: "transcranial-magnetic-stimulation-tms",
  //   },
  {
    key: "",
    label: "Massage Therapy",
    slug: "massage-therapy",
  },
  //   {
  //     key: "",
  //     label: "Tubal Ligation",
  //     slug: "tubal-ligation",
  //   },
  //   {
  //     key: "",
  //     label: "Ablation",
  //     slug: "ablation",
  //   },
  //   {
  //     key: "",
  //     label: "Appendectomy",
  //     slug: "appendectomy",
  //   },
  //   {
  //     key: "",
  //     label: "Cardiac Catheterization",
  //     slug: "cardiac-catheterization",
  //   },
  //   {
  //     key: "",
  //     label: "Hernia Surgery",
  //     slug: "hernia-surgery",
  //   },
  //   {
  //     key: "",
  //     label: "X-Ray",
  //     slug: "x-ray",
  //   },
  //   {
  //     key: "",
  //     label: "Mammogram",
  //     slug: "mammogram",
  //   },
  {
    key: "",
    label: "MRI Scan - Cardiac",
    slug: "mri-scan-cardiac",
  },
  {
    key: "",
    label: "Ultrasound Therapy",
    slug: "ultrasound-therapy",
  },
  //   {
  //     key: "",
  //     label: "EEG",
  //     slug: "eeg",
  //   },
  //   {
  //     key: "",
  //     label: "Angiogram",
  //     slug: "angiogram",
  //   },
  {
    key: "",
    label: "Mohs Surgery",
    slug: "mohs-surgery",
  },
  //   {
  //     key: "",
  //     label: "Blood Urea Nitrogen (BUN) Test",
  //     slug: "blood-urea-nitrogen-bun-test",
  //   },
  //   {
  //     key: "",
  //     label: "Photodynamic Therapy (PDT)",
  //     slug: "photodynamic-therapy-pdt",
  //   },
  {
    key: "",
    label: "Endoscopy",
    slug: "endoscopy",
  },
  {
    key: "",
    label: "Rhinoplasty",
    slug: "rhinoplasty",
  },
  //   {
  //     key: "",
  //     label: "Urinary Tract Infection (UTI)",
  //     slug: "urinary-tract-infection-uti",
  //   },
  {
    key: "",
    label: "Colonoscopy",
    slug: "colonoscopy",
  },
  //   {
  //     key: "",
  //     label: "Cataract Surgery",
  //     slug: "cataract-surgery",
  //   },
  {
    key: "",
    label: "Audiogram",
    slug: "audiogram",
  },
  //   {
  //     key: "",
  //     label: "Ozempic / Wegovy (Semaglutide) Weight Loss Drug Consultation",
  //     slug: "ozempic-wegovy-semaglutide-weight-loss-drug-consultation",
  //   },
];

export default topSearchServices;
