import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRouteGuard = ({ children }) => {
  const loggedUser = useSelector((state) => state?.entities?.users?.loggedUser);
  const isAuthenticated = loggedUser ? true : false;
  const location = useLocation();
  const currentPath = location.pathname + location.search;

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={`/sign-in?redirectTo=${encodeURIComponent(currentPath)}`} />
  );
};

export default PrivateRouteGuard;
