import MainLayout from "components/layout/MainLayout";
import Loadable from "components/ui/Loadable";
import { ROUTES } from "constants";
import { lazy } from "react";
import TermsCondition from "views/public/terms-condition/TermsCondition";
import PrivacyPolicy from "views/public/privacy-policy/PrivacyPolicy";
const DashboardDefault = Loadable(
  lazy(() => import("views/private/dashboard"))
);
const DoctorProfilePage = Loadable(lazy(() => import("views/public/profile")));
const SearchDoctorPage = Loadable(lazy(() => import("views/public/search")));
const ManageProfile = Loadable(
  lazy(() => import("views/private/manage-profile"))
);
const ReviewAndBook = Loadable(lazy(() => import("views/public/booking")));

const MainRoutes = {

  path: ROUTES.Home,
  element: <MainLayout />,

  children: [
    {
      path: ROUTES.Home,
      element: <DashboardDefault />,
    },
    {
      path: ROUTES.DoctorSearch,
      element: <SearchDoctorPage />,
    },
    {
      path: ROUTES.DoctorProfile,
      element: <DoctorProfilePage />,
    },
    {
      path: ROUTES.ManageProfile,
      element: <ManageProfile />,
    },
    {
      path: ROUTES.DoctorBooking,
      element: <ReviewAndBook />,
    },
    {
      path: ROUTES.TermsCondition,
      element: <TermsCondition />
    },
    {
      path: ROUTES.PrivacyPolicy,
      element: <PrivacyPolicy />
    }
  ],
};

export default MainRoutes;
