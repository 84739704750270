import { Grid, ListItem, ListItemText, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchFilteredDoctorList } from "store/doctors";

const Structure = ({ data }) => {
  const featureSpecialities = data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleTopSpecialities = (id) => {
    dispatch(
      fetchFilteredDoctorList(`/users/get-relevent-doctor?specialities=${id}`)
    );
    navigate(`/doctor-search?specialities=${id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={3}>
      {featureSpecialities?.map((data) => (
        <Grid item xs={4}>
          <ListItem
            onClick={() => handleTopSpecialities(data?.id)}
            key={data?.id}
            disablePadding
            sx={{
              cursor: "pointer",
            }}
          >
            <ListItemText primary={data?.text} sx={{
              "& .MuiTypography-root": {
                color: theme.palette.background.paper,
              }
            }} />
          </ListItem>
        </Grid>
      ))}
    </Grid>
  );
};

export default Structure;
