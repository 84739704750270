import { RestMethods } from "constants";
import ApiClient from "services/ApiClient";
import { apiCallBegan, apiCallFailed, apiCallSuccess } from "store/actions";

const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCallBegan.type) return next(action);
    const { method, url, data, onStart, onSuccess, onError } = action.payload;
    next(action);
    if (onStart) dispatch({ type: onStart });
    let result;
    try {
      switch (method) {
        case RestMethods.GET:
          result = await ApiClient.get(url, data);
          break;

        case RestMethods.POST:
          result = await ApiClient.post(url, data);
          break;

        default:
        // Add other messages like PUT, PATCH, DELETE
      }

      dispatch(apiCallSuccess(result));
      if (onSuccess) dispatch({ type: onSuccess, payload: result });
    } catch (error) {
      dispatch(apiCallFailed(error.message));

      if (onError) dispatch({ type: onError, payload: error.response.data });
    }
  };

export default api;
