import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const PublicRouteGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const loggedUser = useSelector((state) => state?.entities?.users?.loggedUser);
  const isAuthenticated = loggedUser ? true : false;
  const params = new URLSearchParams(location.search);
  const redirectTo = params.get("redirectTo") || "/";
  return isAuthenticated ? (
    redirectTo === "/" ? (
      <Navigate to="/" />
    ) : (
      navigate(redirectTo)
    )
  ) : (
    children
  );
};

export default PublicRouteGuard;
