import { createSlice } from "@reduxjs/toolkit";
import { RestMethods } from "constants";
import { getResponse } from "utils/get-response";
import { apiCallBegan } from "./actions";
import { toast } from "react-toastify";
import { ApiErrorMessages } from "constants";

const initialState = {
  doctorsList: [],
  doctorsListLoader: false,
  timeSlotsDuration: {
    from: null,
    to: null,
  },
  doctorTotalCount: 0,
  globalDoctorSpecialties: [],
  globalDoctorSpecialtiesLoading: false,

  globalDoctorServices: [],
  globalDoctorServicesLoading: false,
};

const slice = createSlice({
  name: "doctors",
  initialState,
  reducers: {
    doctorsListRequested: (state) => {
      state.doctorsList = [];
      state.doctorsListLoader = true;
    },

    doctorsListReceived: (state, { payload }) => {
      payload = getResponse(payload);
      const doctors = payload?.doctors;
      state.doctorsList = doctors;
      // This is call only 1 time.
      state.timeSlotsDuration = {
        from: payload?.duration?.from,
        to: payload?.duration?.to,
      };
      state.doctorTotalCount = payload.totalCount;
      state.doctorsListLoader = false;
    },

    doctorsListFailed: (state, action) => {
      state.doctorsListLoader = false;
      toast.error(toast.error(ApiErrorMessages.doctorsListFailed));
    },
    paginatedDoctorRequested: (state) => {
      state.doctorsListLoader = true;
    },
    paginatedDoctorReceived: (state, { payload }) => {
      let response = getResponse(payload);

      // This will call subsequently
      const timeSlotsDuration = {
        from: response?.duration?.from,
        to: response?.duration?.to,
      };
      state.timeSlotsDuration = timeSlotsDuration;
      response = response?.data;
      const newDoctorData = state?.doctorsList?.map((doctor) => {
        return {
          ...doctor,
          dayWiseTimeSlots: response[doctor?.doctor?.id],
        };
      });
      state.doctorsList = newDoctorData;
      state.doctorsListLoader = false;
    },

    paginatedDoctorFailed: (state) => {
      state.doctorsListLoader = false;
      toast.error(toast.error(ApiErrorMessages.paginatedDoctorFailed));
    },

    allDoctorServicesRequested: (state, { payload }) => {
      state.globalDoctorServicesLoading = true;
    },

    allDoctorServicesReceived: (state, { payload }) => {
      state.globalDoctorServicesLoading = false;
    },

    allDoctorServicesFailed: (state, { payload }) => {
      state.globalDoctorServicesLoading = false;
    },

    allDoctorSpecialtiesRequested: (state, { payload }) => {
      state.globalDoctorSpecialtiesLoading = true;
    },
    allDoctorSpecialtiesReceived: (state, { payload }) => {
      let response = getResponse(payload);
      state.globalDoctorSpecialties = response.specialities;
      state.globalDoctorSpecialtiesLoading = false;
    },
    allDoctorSpecialtiesFailed: (state, { payload }) => {
      state.globalDoctorSpecialtiesLoading = false;
      toast.error(toast.error(ApiErrorMessages.allDoctorSpecialtiesFailed));
    },
  },
});

export const {
  doctorsListRequested,
  doctorsListReceived,
  doctorsListFailed,
  paginatedDoctorRequested,
  paginatedDoctorReceived,
  paginatedDoctorFailed,
  allDoctorServicesRequested,
  allDoctorServicesReceived,
  allDoctorServicesFailed,
  allDoctorSpecialtiesRequested,
  allDoctorSpecialtiesReceived,
  allDoctorSpecialtiesFailed,
} = slice.actions;

export default slice.reducer;

export const fetchFilteredDoctorList = (query) =>
  apiCallBegan({
    url: query,
    method: RestMethods.GET,
    onStart: doctorsListRequested.type,
    onSuccess: doctorsListReceived.type,
    onError: doctorsListFailed.type,
  });

export const getPaginatedDoctorTimeSlots = (body) => {
  return apiCallBegan({
    url: "/doctor/get-paginated-time-slots",
    data: body,
    method: RestMethods.POST,
    onStart: paginatedDoctorRequested.type,
    onSuccess: paginatedDoctorReceived.type,
    onError: paginatedDoctorFailed.type,
  });
};

export const getAllServices = () => {
  return apiCallBegan({
    url: "/speciality",
    method: RestMethods.GET,
    onStart: allDoctorServicesRequested.type,
    onSuccess: allDoctorServicesReceived.type,
    onError: allDoctorServicesFailed.type,
  });
};

export const getAllSpecialties = () => {
  return apiCallBegan({
    url: "/speciality",
    method: RestMethods.GET,
    onStart: allDoctorSpecialtiesRequested.type,
    onSuccess: allDoctorSpecialtiesReceived.type,
    onError: allDoctorSpecialtiesFailed.type,
  });
};
