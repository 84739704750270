import { useTheme } from "@emotion/react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import DoctorImage from "assets/images/users/Doctor.png";
import PatientImage from "assets/images/users/Patient.png";
import { PathName, ROLE, ROUTES } from "constants";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LogoSection from "../LogoSection";
import BrowseMoreModal from "./BrowseMoreModal";
import ProfileSection from "./ProfileSection";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;

  const [open, setOpen] = useState(false);
  const [browseMoreOpen, setBrowseMoreOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [current, setCurrent] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showHeaderSearch, setShowHeaderSearch] = useState(false);

  const openMenu = Boolean(anchorEl);
  const theme = useTheme();

  const handleOpen = (value) => {
    setOpen(true);
    setValue(0);
    setCurrent(value);
  };
  const handleClose = () => {
    setOpen(false);
    setCurrent("");
  };

  const currentUser = useSelector(
    (state) => state.entities.users.isLoginSuccess === true
  );

  useEffect(() => {
    if (currentUrl === ROUTES.DoctorSearch) {
      setShowHeaderSearch(true);
    }
  }, [currentUrl]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const reRouteUser = (user, path) => {
    if (path === PathName.SignUp) {
      if (user === ROLE.Doctor) {
        window.location.href = `${process.env.REACT_APP_ADMIN_PANEL}${ROUTES.SignUp}`;
      } else {
        navigate(ROUTES.SignUp);
      }
    } else if (path === PathName.SignIn) {
      if (user === ROLE.Doctor) {
        window.location.href = `${process.env.REACT_APP_ADMIN_PANEL}${ROUTES.SignIn}`;
      } else {
        navigate(ROUTES.SignIn);
      }
    }
  };

  const exploreMoreHandler = () => {
    setBrowseMoreOpen((prevState) => !prevState);
  };

  return (
    <>
      <Box className="hero-header" sx={{ borderBottom: "2px solid  #EDEFF6" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          className="items-collection"
        >
          <LogoSection />
          <Stack direction={"row"}>
            <Box sx={{ display: "flex", mx: 2 }}>
              {!["/sign-up", "/sign-in"].includes(currentUrl) ? (
                <Button
                  variant="text"
                  onClick={exploreMoreHandler}
                  size="small"
                  sx={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    gap: "1em",
                    my: "auto",
                    color: theme.palette.dark[900],
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: theme.palette.primary.main
                    },
                  }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  Browse More
                  {!browseMoreOpen && (
                    <ExpandMoreIcon color="background.paper" />
                  )}
                  {browseMoreOpen && (
                    <ExpandLessIcon color="background.paper" />
                  )}
                </Button>
              ) : (
                <></>
              )}
            </Box>

            {/* {showHeaderSearch && <HeroSearch />} */}
            <Box sx={{ display: "flex" }}>
              {currentUser ? (
                <Box sx={{ alignContent: "center" }}>
                  <ProfileSection />
                </Box>
              ) : !["/sign-up", "/sign-in"].includes(currentUrl) ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      [theme.breakpoints.down("sm")]: {
                        display: "none",
                      },
                    }}
                  >
                    <Button
                      variant="default"
                      onClick={() => handleOpen("login")}
                      size="small"
                      sx={{
                        ml: 2,
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        gap: "1em",
                        backgroundColor: "transparent",
                        color: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.primary.main}`,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.background.paper,
                          border: `1px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      login
                    </Button>

                    <Button
                      variant="primary"
                      onClick={() => handleOpen("signup")}
                      size="small"
                      sx={{
                        ml: 2,
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        gap: "1em",
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.primary.main}`,
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: theme.palette.primary.main,
                          border: `1px solid ${theme.palette.primary.main}`
                        },
                      }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      Register
                    </Button>
                  </Box>

                  <IconButton
                    aria-label="delete"
                    sx={{
                      display: "none",
                      [theme.breakpoints.down("sm")]: {
                        display: "block",
                        width: "40px",
                        height: "40px",
                      },
                    }}
                    onClick={handleClick}
                  >
                    <MenuIcon />
                  </IconButton>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            borderRadius: "10px",
            [theme.breakpoints.down("sm")]: {
              width: 300,
            },
          }}
        >
          <Tabs
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
            sx={{
              "& .MuiButtonBase-root.MuiTab-root": {
                width: "50%",
                padding: "20px 16px",
              },
            }}
          >
            <Tab label="Patient" />
            <Tab label="Doctor" />
          </Tabs>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1em",
              flexWrap: "wrap",
            }}
          >
            {value === 0 ? (
              <Box
                sx={{
                  width: "300px",
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "0.7em",
                  }}
                >
                  <img
                    src={PatientImage}
                    style={{ width: "90px", height: "90px" }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ marginTop: ".5rem", color: theme.palette.grey[600] }}
                  >
                    {current === "login"
                      ? "Log in "
                      : current === "signup"
                        ? "Register "
                        : null}
                    to view / book appointments and doctors. Communicate with
                    your doctor and schedule your appointments.
                  </Typography>

                  {current === "login" ? (
                    <Button
                      variant="contained"
                      sx={{ width: "100%" }}
                      onClick={() => reRouteUser(ROLE.Patient, PathName.SignIn)}
                    >
                      Login
                    </Button>
                  ) : current === "signup" ? (
                    <Button
                      variant="contained"
                      sx={{ width: "100%" }}
                      onClick={() => reRouteUser(ROLE.Patient, PathName.SignUp)}
                    >
                      Register
                    </Button>
                  ) : null}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "300px",
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "0.7em",
                  }}
                >
                  <img
                    src={DoctorImage}
                    style={{ width: "90px", height: "90px" }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ marginTop: ".5rem", color: theme.palette.grey[600] }}
                  >
                    {current === "login"
                      ? "Log in "
                      : current === "signup"
                        ? "Register "
                        : null}
                    to view all appointments and patients. Communicate with your
                    patients and schedule your availability.
                  </Typography>
                  {current === "login" ? (
                    <Button
                      variant="contained"
                      sx={{ width: "100%" }}
                      onClick={() => reRouteUser(ROLE.Doctor, PathName.SignIn)}
                    >
                      Login
                    </Button>
                  ) : current === "signup" ? (
                    <Button
                      variant="contained"
                      sx={{ width: "100%" }}
                      onClick={() => reRouteUser(ROLE.Doctor, PathName.SignUp)}
                    >
                      Register
                    </Button>
                  ) : null}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            width: 280,
            // height: 120,
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
        }}
      >
        <MenuItem
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
            padding: "1em 2em",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              borderRadius: "10px",
            },
          }}
          onClick={() => handleOpen("login")}
        >
          <LoginIcon color="primary" />
          <Typography variant="span">Login</Typography>
        </MenuItem>
        <MenuItem
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
            padding: "1em 2em",
          }}
          onClick={() => handleOpen("signup")}
        >
          <PersonAddIcon color="primary" />
          <Typography variant="span">Register</Typography>
        </MenuItem>
      </Menu>
      {browseMoreOpen && (
        <BrowseMoreModal exploreMoreHandler={exploreMoreHandler} />
      )}
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
