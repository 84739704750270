import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  slot: {},
  doctorId: "",
  userId: "",
  loadingSlot: false,
};

const slice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    appointmentsReceived: (state, { payload }) => {
      // use user id from the user slice
      state.slot = payload;
    },
  },
});

export const { appointmentsReceived } = slice.actions;

export default slice.reducer;
