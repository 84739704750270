const topSearchSpecialties = [
  {
    key: 1,
    label: "Family Physician",
    slug: "family-physician",
  },
  {
    key: 2,
    label: "Psychiatrist",
    slug: "psychiatrist",
  },
  {
    key: 3,
    label: "Dentist",
    slug: "dentist",
  },
  {
    key: 4,
    label: "OB-GYN",
    slug: "ob-gyn",
  },
  {
    key: 5,
    label: "Psychologist",
    slug: "psychologist",
  },
  {
    key: 6,
    label: "Therapist / Counselor",
    slug: "therapist-/-counselor",
  },
  {
    key: 7,
    label: "Dietitian",
    slug: "dietitian",
  },
  {
    key: 8,
    label: "Chiropractor",
    slug: "chiropractor",
  },
  {
    key: 9,
    label: "Optometrist",
    slug: "optometrist",
  },
  {
    key: 10,
    label: "Ophthalmologist",
    slug: "ophthalmologist",
  },
  {
    key: 11,
    label: "Podiatrist",
    slug: "podiatrist",
  },
  {
    key: 12,
    label: "Allergist",
    slug: "allergist",
  },
  {
    key: 13,
    label: "Dermatologist",
    slug: "dermatologist",
  },
  {
    key: 14,
    label: "Orthopedic Surgeon",
    slug: "orthopedic-surgeon",
  },
];

export default topSearchSpecialties;
