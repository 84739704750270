import ButtonBase from "@mui/material/ButtonBase";
import Logo from "components/ui/Logo";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MENU_OPEN } from "store/actions";

const LogoSection = () => {
  const dispatch = useDispatch();
  const defaultId = useSelector(
    (state) => state.entities.customization.defaultId
  );
  return (
    <ButtonBase
      disableRipple
      onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })}
      component={Link}
      to={"/"}
    >
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
