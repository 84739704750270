import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

const TermsCondition = () => {
  const navigate = useNavigate();
  return (
    <Container sx={{ py: 5 }} maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" gutterBottom>
          Last updated: [10/24/2024]
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to <strong>Global Care Finder</strong>. These Terms and
          Conditions govern your use of our website, mobile application, and
          services. By accessing or using Global Care Finder, you agree to be
          bound by these Terms and Conditions. If you do not agree to these
          terms, please refrain from using our platform.
        </Typography>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1">
            By accessing and using the Global Care Finder platform, you agree to
            comply with these Terms and Conditions, as well as all applicable
            laws and regulations. If you do not agree with any of these terms,
            you are prohibited from using or accessing this platform.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            2. Eligibility
          </Typography>
          <Typography variant="body1">
            Global Care Finder is intended for individuals who are at least 18
            years old. By using our platform, you represent that you are of
            legal age to form a binding contract and meet the eligibility
            requirements. If you are under 18, you must have permission from a
            parent or legal guardian to use the platform.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            3. Platform Purpose
          </Typography>
          <Typography variant="body1">
            Global Care Finder connects individuals with healthcare providers
            globally. We act as a facilitator between users and providers and do
            not provide medical advice, diagnosis, or treatment. The healthcare
            professionals listed on the platform are independent third parties,
            and Global Care Finder is not responsible for the quality or
            outcomes of services provided by them.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            4. User Responsibilities
          </Typography>
          <Typography variant="body1">
            When using Global Care Finder, you agree to:
          </Typography>
          <ul>
            <li>Provide accurate, current, and complete information.</li>
            <li>Use the platform only for lawful purposes.</li>
            <li>
              Refrain from using the platform to harm, harass, or exploit
              others.
            </li>
            <li>
              Avoid posting or transmitting any content that is illegal,
              harmful, or violates the rights of others.
            </li>
          </ul>
          <Typography variant="body1">
            You are solely responsible for maintaining the confidentiality of
            your account login details and for all activities conducted under
            your account.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            5. Healthcare Providers
          </Typography>
          <Typography variant="body1">
            Global Care Finder does not verify the credentials, qualifications,
            or experience of healthcare providers listed on the platform. We
            provide information and tools to connect you with providers, but the
            decision to use a provider is entirely yours. You acknowledge that
            Global Care Finder is not liable for any actions, advice, or
            treatment provided by healthcare professionals.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            6. Payments and Fees
          </Typography>
          <Typography variant="body1">
            Certain services on Global Care Finder may require payment, such as
            booking consultations or accessing premium features. Payments are
            processed through third-party payment providers, and Global Care
            Finder is not responsible for any issues related to payment
            processing. Fees are non-refundable unless stated otherwise. Please
            review any specific service fees before making a payment.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            7. Cancellations and Refunds
          </Typography>
          <Typography variant="body1">
            Consultation cancellations and refunds are subject to the individual
            healthcare provider’s policy. It is your responsibility to review
            these terms before booking any appointments. Global Care Finder is
            not responsible for refunds or disputes between users and healthcare
            providers.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            8. Privacy Policy
          </Typography>
          <Typography variant="body1">
            Your privacy is important to us. By using Global Care Finder, you
            agree to our collection and use of personal information as outlined
            in our{" "}
            <Typography variant="body1" component={Link} to={"/privacy-policy"}>
              Privacy Policy.
            </Typography>{" "}
            This includes the collection of your name, contact information, and
            health-related data as necessary to provide services.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            9. Limitation of Liability
          </Typography>
          <Typography variant="body1">
            To the maximum extent permitted by law, Global Care Finder is not
            liable for any damages or losses arising from:
          </Typography>
          <ul>
            <li>The use or inability to use the platform.</li>
            <li>
              The reliance on any information or services provided through the
              platform.
            </li>
            <li>
              Any actions or advice of healthcare providers connected through
              the platform.
            </li>
          </ul>
          <Typography variant="body1">
            Global Care Finder does not guarantee the accuracy, reliability, or
            completeness of the information provided by users or healthcare
            professionals.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            10. Termination of Service
          </Typography>
          <Typography variant="body1">
            We reserve the right to suspend or terminate your access to Global
            Care Finder at any time for any reason, including violations of
            these Terms and Conditions or any other legal or regulatory
            obligations.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            11. Changes to the Terms
          </Typography>
          <Typography variant="body1">
            Global Care Finder may update these Terms and Conditions from time
            to time. Any changes will be posted on this page, and the "Last
            Updated" date will be revised. Continued use of the platform after
            such changes constitutes your acceptance of the updated terms.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            12. Intellectual Property
          </Typography>
          <Typography variant="body1">
            All content, logos, and trademarks displayed on Global Care Finder
            are the property of Global Care Finder or its licensors. You may not
            use, reproduce, or distribute any content without explicit
            permission.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            13. Governing Law
          </Typography>
          <Typography variant="body1">
            These Terms and Conditions are governed by and construed in
            accordance with the laws of the United States and the State of
            Illinois. Any disputes arising out of these terms will be subject to
            the exclusive jurisdiction of the courts in Wilmington, Illinois.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            14. Contact Information
          </Typography>
          <Typography variant="body1">
            If you have any questions or concerns about these Terms and
            Conditions, please contact us at: Email:
            support@globalcarefinder.com
          </Typography>
        </Box>
      </Box>
      <Button variant="contained" onClick={() => navigate("/sign-up")}>
        {" "}
        Continue to SignUp
      </Button>
    </Container>
  );
};

export default TermsCondition;
