export const specificCountries = [
  { key: "india", label: "India", countryCode: "IN" },
  { key: "turkey", label: "Turkey", countryCode: "TR" },
  { key: "japan", label: "Japan", countryCode: "JP" },
  { key: "canada", label: "Canada", countryCode: "CA" },
  { key: "united-kingdom", label: "United Kingdom", countryCode: "GB" },
  { key: "brazil", label: "Brazil", countryCode: "BR" },
  { key: "singapore", label: "Singapore", countryCode: "SG" },
  { key: "costa-rica", label: "Costa Rica", countryCode: "CR" },
  { key: "south-korea", label: "South Korea", countryCode: "KR" },
  { key: "mexico", label: "Mexico", countryCode: "MX" },
  { key: "thailand", label: "Thailand", countryCode: "TH" },
  { key: "spain", label: "Spain", countryCode: "ES" },
  { key: "malaysia", label: "Malaysia", countryCode: "MY" },
  { key: "germany", label: "Germany", countryCode: "DE" },
  { key: "taiwan", label: "Taiwan", countryCode: "TW" },
  { key: "united-states", label: "United States", countryCode: "US" },
  {
    key: "united-arab-emirates",
    label: "United Arab Emirates",
    countryCode: "AE",
  },
  { key: "czech-republic", label: "Czech Republic", countryCode: "CZ" },
  { key: "italy", label: "Italy", countryCode: "IT" },
  { key: "france", label: "France", countryCode: "FR" },
  { key: "colombia", label: "Colombia", countryCode: "CO" },
  { key: "hungary", label: "Hungary", countryCode: "HU" },
  { key: "sweden", label: "Sweden", countryCode: "SE" },
  { key: "australia", label: "Australia", countryCode: "AU" },
  { key: "dominican-republic", label: "Dominican Republic", countryCode: "DO" },
  { key: "argentina", label: "Argentina", countryCode: "AR" },
  { key: "oman", label: "Oman", countryCode: "OM" },
  { key: "panama", label: "Panama", countryCode: "PA" },
];
