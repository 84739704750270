import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import "assets/scss/style.scss";
import { RouterProvider } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import NavigationScroll from "./components/layout/NavigationScroll";
import themes from "./components/themes";
import router from "./routes";

function App() {
  const customization = 5;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <NavigationScroll>
          <RouterProvider router={router} />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
