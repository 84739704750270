import MainLayout from "components/layout/MainLayout";
import Loadable from "components/ui/Loadable";
import PublicRouteGuard from "guards/PublicRouteGuard";
import { lazy } from "react";

const SignIn = Loadable(lazy(() => import("views/public/auth/SignIn")));
const SignUp = Loadable(lazy(() => import("views/public/auth/SignUp")));
const ForgotPassword = Loadable(lazy(() => import("views/public/auth/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("views/public/auth/ResetPassword")));


const AuthenticationRoutes = {
  path: "/",
  element: (
    <PublicRouteGuard>
      <MainLayout />,
    </PublicRouteGuard>
  ),
  children: [
    {
      path: "/sign-in",
      element: <SignIn />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
  ],
};

export default AuthenticationRoutes;
